import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellCheckboxRenderer,
} from '@mui/x-data-grid-pro';
import { Alert, AlertTitle, Typography, Box, Stack } from '@mui/material';
import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined, WarningAmber } from '@mui/icons-material';
import {
  ICD_CODES_URL_PATH,
  mapPageInsightStatusToDisplayStatus,
  selectPageInsightStatusOptions,
  NEEDS_REVIEW_STATUS,
} from './insights';
import MedicalsDiagnosesDetailPanelContent from './MedicalsDiagnosesDetailPanelContent';
import TableNoRowsOverlay from './TableNoRowsOverlay';
import { setSelectedRowsAndIcdCodeIds } from '../redux/slices/icdCodesSlice';
import { setToast } from '../redux/slices/globalToastSlice';
import { userHasPermission } from '../common';
import { useGetEntityInsightsQuery } from '../services/insights/insightsApi';
import { apiSlice } from '../services/apiSlice';

const slots = {
  detailPanelExpandIcon: KeyboardArrowDownOutlined,
  detailPanelCollapseIcon: KeyboardArrowUpOutlined,
  noRowsOverlay: TableNoRowsOverlay,
};

const slotProps = {
  noRowsOverlay: {
    text: 'No diagnoses',
  },
};

const MedicalsDiagnosesTable = ({
  documentId,
  handleViewPageInDocument,
  user,
  icdSummaryData,
  icdCodesAlerts,
  userData,
}) => {
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);

  const { isLoading: isIcdDataLoading } = useGetEntityInsightsQuery({ documentId, insightsType: ICD_CODES_URL_PATH });

  const { selectedRowsAndIcdCodeIds } = useSelector((state) => state.IcdCodes);
  // The array of keys corresponds to the selected parent rows.
  const rowSelectionModel = Object.keys(selectedRowsAndIcdCodeIds) || [];

  const dispatch = useDispatch();

  const columns = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      renderCell: (params) => {
        // Partial sub row selections would show the Indeterminate checkbox state.
        const isPartialSelection = selectedRowsAndIcdCodeIds[params.row.value]
          ? selectedRowsAndIcdCodeIds[params.row.value].length < params.row.entityIds.length
          : false;

        return <GridCellCheckboxRenderer {...params} indeterminate={isPartialSelection} />;
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    },
    {
      field: 'icdCode',
      headerName: 'ICD code',
      width: 200,
      editable: false,
      renderCell: ({ row }) => {
        const { value, status, count, excludedCount } = row;
        const displayCount = `${count - excludedCount}/${count}`;
        return (
          <>
            {status === NEEDS_REVIEW_STATUS && <WarningAmber color="warning" sx={{ mr: 1 }} />}
            <Stack mt={0.5} mb={0.5}>
              <Typography variant="tableP1">{`${value} (${displayCount})`}</Typography>
            </Stack>
          </>
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
      editable: false,
      renderCell: (params) => {
        return <Typography variant="tableP1">{params.row.description}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      editable: true,
      filterable: true,
      type: 'singleSelect',
      valueOptions: selectPageInsightStatusOptions,
      renderCell: (params) => {
        return <Typography variant="tableP1">{mapPageInsightStatusToDisplayStatus[params.row.status]}</Typography>;
      },
    },
  ];

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <MedicalsDiagnosesDetailPanelContent
        icdCode={row.value}
        documentId={documentId}
        handleViewPageInDocument={handleViewPageInDocument}
        userData={userData}
      />
    ),
    [documentId, handleViewPageInDocument, userData]
  );
  const getRowHeight = useCallback(() => 'auto', []);

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const handleDetailPanelExpandedRowIdsChange = useCallback((newIds) => {
    setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds);
  }, []);

  const getRowClassName = useCallback((params) => {
    const evenOrOddClassName = params.indexRelativeToCurrentPage % 2 === 0 ? 'doc-row-even' : 'doc-row-odd';
    if (params.row.status === NEEDS_REVIEW_STATUS) {
      return `${evenOrOddClassName} grid-row-warning`;
    }
    return evenOrOddClassName;
  }, []);

  const handleRowSelectionChange = useCallback(
    (newRowSelectionModel) => {
      // The row selections here are the parent rows (ICD codes). Each row is set as the key, with the value being all the sub-row selections.
      const newSelectedIcdCodeIds = {};

      newRowSelectionModel.forEach((parentRowId) => {
        if (selectedRowsAndIcdCodeIds[parentRowId]) {
          newSelectedIcdCodeIds[parentRowId] = [...selectedRowsAndIcdCodeIds[parentRowId]];
        } else {
          const row = icdSummaryData.find((icdCode) => icdCode.value === parentRowId) || {};
          newSelectedIcdCodeIds[parentRowId] = row?.entityIds;
        }
      });

      dispatch(setSelectedRowsAndIcdCodeIds(newSelectedIcdCodeIds));
    },
    [icdSummaryData, selectedRowsAndIcdCodeIds, dispatch]
  );

  const processRowUpdate = (updatedRow, originalRow) => {
    if (updatedRow.status !== originalRow.status) {
      dispatch(
        apiSlice.endpoints.updateEntityInsightDetails.initiate({
          documentId,
          user,
          insightsType: ICD_CODES_URL_PATH,
          insightValues: [updatedRow.value],
          entityIdsToUpdate: updatedRow.entityIds,
          newStatus: updatedRow.status,
        })
      ).then(({ error }) => {
        if (error) {
          console.log('error', error);
          dispatch(setToast({ isOpen: true, severity: 'error', message: 'Error with updating ICD code(s)' }));
        }
      });
    }

    return updatedRow;
  };

  const handleProcessRowError = useCallback((error) => console.log(error), []);

  return (
    <>
      {icdCodesAlerts.map(({ alertSeverity, alertTitle, alertMessage }, index) => {
        return (
          <Alert key={index} severity={alertSeverity} sx={{ mb: 1 }}>
            {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
            {alertMessage}
          </Alert>
        );
      })}
      {/* These two boxes are a bit of a hack to force the browser to calculate the DataGrid's dimensions, allowing it to be height responsive. */}
      <Box sx={{ flex: 1, position: 'relative' }}>
        <Box sx={{ position: 'absolute', inset: 0 }}>
          <DataGridPro
            loading={isIcdDataLoading}
            rows={icdSummaryData}
            columns={columns}
            getRowHeight={getRowHeight}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
            disableRowSelectionOnClick
            disableColumnReorder
            disableColumnSelector
            disableColumnPinning
            slots={slots}
            slotProps={slotProps}
            getRowId={(row) => row.value}
            getRowClassName={getRowClassName}
            checkboxSelection={userHasPermission('DemandCompose', userData)}
            onRowSelectionModelChange={handleRowSelectionChange}
            rowSelectionModel={rowSelectionModel}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowError}
          />
        </Box>
      </Box>
    </>
  );
};

export default MedicalsDiagnosesTable;
