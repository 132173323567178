import React from 'react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import { TableCell } from '@tiptap/extension-table-cell';
import { TableHeader } from '@tiptap/extension-table-header';
import { TableRow } from '@tiptap/extension-table-row';
import {
  RichTextEditor,
  MenuControlsContainer,
  MenuButtonAlignCenter,
  MenuButtonAlignJustify,
  MenuButtonAlignLeft,
  MenuButtonAlignRight,
  MenuButtonBold,
  MenuButtonBulletedList,
  MenuButtonItalic,
  MenuButtonOrderedList,
  MenuButtonBlockquote,
  MenuButtonRedo,
  MenuButtonUnderline,
  MenuButtonUndo,
  MenuButtonIndent,
  MenuButtonUnindent,
  MenuDivider,
  TableImproved,
  MenuButtonAddTable,
  TableMenuControls,
} from 'mui-tiptap';
import { Box, keyframes } from '@mui/material';
import './ConfiguredRichTextEditor.css';
import { Indent, getIndent, getOutdent } from './CustomExtensions/indent.ts';
import AiGenerate from './CustomExtensions/aiGenerate.js';
import PageBreak from './CustomExtensions/pageBreak.js';
import MenuButtonGenerate from './CustomButtons/MenuButtonGenerate.jsx';
import MenuButtonPageBreak from './CustomButtons/MenuButtonPageBreak.jsx';

const extensions = [
  StarterKit,
  Underline,
  TextAlign.configure({ types: ['paragraph'] }),
  Indent,
  AiGenerate,
  TextStyle,
  TableImproved.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  PageBreak,
];

const EditorMenuControls = ({ editor, showGenerateButton, fieldName, documentId, user }) => {
  // Helper function to prevent blur on button click
  const handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <Box onMouseDown={handleMouseDown}>
      <MenuControlsContainer>
        {showGenerateButton && (
          <>
            <MenuButtonGenerate fieldName={fieldName} documentId={documentId} user={user} onMouseDown={handleMouseDown} />
            <MenuDivider />
          </>
        )}
        <MenuButtonBold />
        <MenuButtonItalic />
        <MenuButtonUnderline />
        <MenuDivider />
        <MenuButtonBlockquote />
        <MenuButtonBulletedList />
        <MenuButtonOrderedList />
        <MenuButtonUnindent
          tooltipLabel="Decrease indent"
          disabled={!editor?.isEditable}

          onClick={() => getOutdent()({ editor })}
        />
        <MenuButtonIndent
          tooltipLabel="Increase indent"
          disabled={!editor?.isEditable}

          onClick={() => getIndent()({ editor })}
        />
        <MenuDivider />
        <MenuButtonAlignLeft />
        <MenuButtonAlignCenter />
        <MenuButtonAlignRight />
        <MenuButtonAlignJustify />
        <MenuDivider />
        <MenuButtonPageBreak />
        <MenuDivider />
        <MenuButtonUndo />
        <MenuButtonRedo />
        <MenuDivider />
        <MenuButtonAddTable />
        {editor.isActive('table') && <TableMenuControls />}
      </MenuControlsContainer>
    </Box>
  );
}

const slideDown = keyframes`
  from {
    max-height: 0;
    opacity: 1;
  }
  to {
    max-height: 200px; /* Adjust this value based on the height you expect for the controls */
    opacity: 1;
  }
`;

const ConfiguredRichTextEditor = ({ content, onChange, showGenerateButton = false, fieldName, documentId, user }) => {

  return (
    <Box
      sx={{
        '.ProseMirror': {
          minHeight: '100px',
          maxHeight: '350px',
          overflow: 'auto',
        },
      }}
    >
      <RichTextEditor
        extensions={extensions}
        content={content}
        onUpdate={onChange}
        parseOptions={{ preserveWhitespace: 'full' }}
        renderControls={(editor) => (
          editor.isFocused && (
            <Box
              sx={{
                animation: `${slideDown} 0.5s ease-out`,
              }}
            >
              <EditorMenuControls
                editor={editor}
                showGenerateButton={showGenerateButton}
                fieldName={fieldName}
                documentId={documentId}
                user={user}
              />
            </Box>
          )
        )}
      />
    </Box>
  );
};

export default ConfiguredRichTextEditor;
