import React, { useCallback } from 'react';
import { Stack, Typography, Link, Box } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { MRI_URL_PATH } from './insights';
import { useGetEntityInsightsDetailsQuery } from '../services/insights/insightsApi';
import { useGetAllFilesByDocumentIdQuery } from '../services/documentFiles/documentFilesApi';

const dataGridStyles = { backgroundColor: '#FFFFFF' };

const initialState = {
  sorting: {
    sortModel: [{ field: 'page', sort: 'asc' }],
  },
};

const MedicalsMRIDetailPanelContent = ({ mriValue, mriEntityIds, documentId, handleViewPageInDocument }) => {
  const { data: mriDetails = [], isLoading: isMriDetailsLoading } = useGetEntityInsightsDetailsQuery({
    documentId,
    insightsType: MRI_URL_PATH,
    insightEntityIds: mriEntityIds,
  });
  const { data: documentFiles = [] } = useGetAllFilesByDocumentIdQuery(documentId);

  const columns = [
    {
      field: 'document',
      headerName: 'Document',
      width: 400,
      editable: false,
      filterable: false,
      renderCell: ({ row }) => {
        const { entityInsightData, fileEntityId } = row;
        const file = documentFiles.find((file) => file.fileEntityId === fileEntityId);
        const documentName = file ? file?.fileEntityData?.fileName : '';

        return entityInsightData?.generated_page_number ? (
          <Link
            component="button"
            onClick={() =>
              handleViewPageInDocument(entityInsightData?.generated_page_number, entityInsightData?.bboxes)
            }
            style={{ textDecoration: 'none', textAlign: 'left' }}
          >
            <Typography variant="tableP1" style={{ color: '#4077B0' }}>
              {documentName}
            </Typography>
          </Link>
        ) : (
          <Typography variant="tableP1">{documentName}</Typography>
        );
      },
    },
    {
      field: 'page',
      headerName: 'Page',
      width: 100,
      editable: false,
      filterable: true,
      valueGetter: (params) => params.row.entityInsightData.generated_page_number,
      renderCell: (params) => {
        return <Typography variant="tableP1">{params.row.entityInsightData.generated_page_number}</Typography>;
      },
    },
  ];

  const useShortDetailPanel = mriDetails.length < 7;

  const getRowId = useCallback((row) => row.entityInsightId, []);

  const getRowHeight = useCallback(() => 43, []);

  return (
    <Stack
      direction="column"
      p={2}
      spacing={1}
      sx={{ backgroundColor: grey[100], borderBottom: '1px solid', borderColor: 'divider' }}
    >
      <Typography variant="body2">References to {mriValue}</Typography>
      <Box
        sx={{
          height: useShortDetailPanel ? '' : '400px',
        }}
      >
        <DataGridPro
          rows={mriDetails}
          columns={columns}
          loading={isMriDetailsLoading}
          density="compact"
          disableRowSelectionOnClick
          disableColumnReorder
          disableColumnSelector
          disableColumnPinning
          sx={dataGridStyles}
          getRowId={getRowId}
          getRowHeight={getRowHeight}
          autoHeight={useShortDetailPanel ? true : false}
          initialState={initialState}
        />
      </Box>
    </Stack>
  );
};

export default MedicalsMRIDetailPanelContent;
